<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header
                    :title="$t('student_card')"
                    :isColumns="true"
                    @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1"
                             v-for="(column, key) in datatable.columns"
                             v-bind:key="key">
                            <b-form-checkbox v-model="column.hidden"
                                             :id="'checkbox-' + key"
                                             :name="'checkbox-' + key"
                                             :value="false"
                                             :unchecked-value="true"
                                             v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile
                    :title="$t('student_card')"
                    :isColumns="true"
                    @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1"
                             v-for="(column, key) in datatable.columns"
                             v-bind:key="key">
                            <b-form-checkbox v-model="column.hidden"
                                             :id="'checkbox-' + key"
                                             :name="'checkbox-' + key"
                                             :value="false"
                                             :unchecked-value="true"
                                             v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus"
                              @filter="filter"
                              @filterClear="filterClear"
                              :export-excel="true">
                <b-row>
                    <b-col sm="6">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input v-model="datatable.queryParams.filter.student_number" min="0"
                                          v-check-min-max-value/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('national_id')">
                            <b-form-input v-model="datatable.queryParams.filter.national_id" min="0"
                                          v-check-min-max-value/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('registration_type')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.registration_type"
                                                 code="registration_types"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name" type="text"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="datatable.queryParams.filter.program_code"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('surname')">
                            <b-form-input v-model="datatable.queryParams.filter.surname" type="text"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('registration_academic_year')">
                            <academic-years-selectbox
                                v-model="datatable.queryParams.filter.registration_academic_year"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('card_status')">
                            <approved-declined-selectbox v-model="datatable.queryParams.filter.card_status"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('registration_season')">
                            <semesters-selectbox v-model="datatable.queryParams.filter.registration_semester"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable v-show="datatable.showTable"
                       :isLoading="datatable.isLoading"
                       :rows="datatable.rows"
                       :columns="datatable.columns"
                       :query-params="datatable.queryParams"
                       :total="datatable.total"
                       @on-per-page-change="onPerPageChange"
                       @on-page-change="onPageChange"/>
            <CommonModal ref="photoModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="closeModal">
                <template v-slot:CommonModalContent>
                    <div id="printCard">
                        <img v-if="asPrint" :src="cardPhoto" style="width: 100%"/>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import ApprovedDeclinedSelectbox from "@/components/interactive-fields/ApprovedDeclinedSelectbox";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import StudentIdCardService from "@/services/StudentIdCardService";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import qs from "qs";
import CommonModal from "@/components/elements/CommonModal";
import toBase64 from "@/helpers/toBase64";

export default {
    components: {
        SemestersSelectbox,
        AcademicYearsSelectbox,
        ApprovedDeclinedSelectbox,
        ProgramSelectbox,
        FacultySelectbox,
        ParameterSelectbox,
        CommonModal,

        Datatable,
        DatatableFilter,

        HeaderMobile,
        Header,

        AppLayout
    },
    data() {
        return {
            asPrint: null,
            cardPhoto: null,
            datatable: {
                filterStatus: true,
                rows: [],
                columns: [
                    {
                        label: "",
                        field: 'buttons',
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [{
                            text: this.$t("create"),
                            class: 'ri-image-add-line align-middle top-minus-1 mr-3 text-muted',
                            callback: ({id}) => {
                                this.previewStudentCard(id)
                            }
                        }, {
                            text: this.$t("print"),
                            class: 'ri-printer-line align-middle top-minus-1 mr-3 text-muted',
                            callback: ({id}) => {
                                this.printCard(id)
                            }
                        }]
                    },
                    {
                        field: 'name',
                        label: this.toUpperCase("name"),
                        sortable: false,
                        hidden: false
                    },
                    {
                        field: 'surname',
                        label: this.toUpperCase('surname'),
                        sortable: false,
                        hidden: false
                    },
                    {
                        field: ("faculty_name"),
                        label: this.toUpperCase("faculty"),
                        sortable: false,
                        hidden: false
                    },
                    {
                        field: ("program_name"),
                        label: this.toUpperCase("program"),
                        sortable: false,
                        hidden: false
                    },
                    {
                        field: "student_number",
                        label: this.toUpperCase("student_number"),
                        sortable: false,
                        hidden: false
                    },
                    {field: "national_id", label: this.toUpperCase("national_id"), sortable: false, hidden: false},
                    {
                        field: ("registration_type"),
                        label: this.toUpperCase("registration_type"),
                        sortable: false,
                        hidden: false
                    },
                    {field: "card_status", label: this.toUpperCase("card_status"), sortable: false, hidden: false},
                    {
                        field: "registration_academic_year",
                        label: this.toUpperCase("registration_academic_year"),
                        sortable: false,
                        hidden: false
                    },
                    {
                        field: "registration_semester",
                        label: this.toUpperCase("registration_season"),
                        sortable: false,
                        hidden: false
                    }
                ],
                queryParams: {
                    filter: {
                        faculty_code: null,
                        program_code: null,
                        student_number: null,
                        national_id: null,
                        registration_type: null,
                        card_status: null,
                        registration_academic_year: null,
                        registration_semester: null
                    },
                    limit: 20,
                    page: 1,
                },
                total: 0,
                showTable: false,
                isLoading: false
            }
        }
    },
    methods: {
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            let config = {
                params: {...this.datatable.queryParams},
                paramsSerializer: (params) => {
                    return qs.stringify(params, {encode: false})
                }
            }

            StudentIdCardService.getAll(config).then(res => {
                this.datatable.rows = res.data.data;
                this.datatable.total = res.data.pagination.total
            }).finally(() => this.datatable.isLoading = false)
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows()
        },
        filterClear() {
            let filterArr = Object.keys(this.datatable.queryParams.filter)
            for (const val of filterArr) {
                this.datatable.queryParams.filter[val] = null
            }
            this.getRows()
        },
        async previewStudentCard(id) {
            try {
                let data = await StudentIdCardService.createCard(id)
                this.asPrint = true;
                this.cardPhoto = toBase64(data);
                this.$refs.photoModal.$refs.commonModal.show()
            } catch (err) {
                this.showErrors(err)
            }
        },
        async printCard(id) {
            try {
                let data = await StudentIdCardService.printCard(id);
                this.cardPhoto = toBase64(data);
                this.asPrint = true;
                setTimeout(async () => {
                    await this.$htmlToPaper('printCard');
                }, 1000)

            } catch (err) {
                this.showErrors(err)
            }
        },
        closeModal() {
            this.$refs.photoModal.$refs.commonModal.hide();
            this.asPrint = null;
            this.cardPhoto = null
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
    }
}
</script>
<style>
:root {
    --srcOfBackground: '';
}

@media print {
    #printCard {
        background-image: var(--srcOfBackground);
    }
}
</style>
